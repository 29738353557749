import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import Article from "../../../components/Article/Article"
import CallToAction from "../../../components/Article/CallToAction"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import i18n from "i18next"
import A from "../../../components/FastLink/A"

const SeoArticle = ({ data }) => {
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "Domena internetowa. Czym jest, jak ją kupić i czy ma wpływ na SEO?",
        description:
          "Dowiedz się, czym jest domena internetowa, z czego się składa i jakie może mieć rozszerzenia oraz sprawdź, czy nazwa domeny ma bezpośredni wpływ na SEO.",
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id": "/baza-wiedzy/artykul/czym-jest-domena-internetowa-i-co-warto-o-niej-wiedziec/",
                name: "Domena internetowa. Czym jest, jak ją kupić i czy ma wpływ na SEO?",
              },
            },
          ],
        },
      }}
    >
      <Breadcrumbs
        path={[
          { url: "/", text: "Główna" },
          {
            url: "/baza-wiedzy/artykuly/",
            text: "Baza wiedzy / Artykuły",
          },
          {
            url: "/baza-wiedzy/artykul/czym-jest-domena-internetowa-i-co-warto-o-niej-wiedziec",
            text: "Domena internetowa. Czym jest, jak ją kupić i czy ma wpływ na SEO?",
          },
        ]}
      />
      <Article
        img={
          <img
            src={data.file.childImageSharp.fluid.src}
            alt="Domena internetowa. Czym jest, jak ją kupić i czy ma wpływ na SEO?"
          />
        }
        slug={"/baza-wiedzy/artykul/czym-jest-domena-internetowa-i-co-warto-o-niej-wiedziec"}
      >
        <h1>Czym jest domena internetowa i co warto o niej wiedzieć?</h1>
        <p>
          <strong>
            Każda strona internetowa ma swój unikalny adres. Dzięki niemu użytkownicy mają możliwość wyświetlać w
            przeglądarce konkretną witrynę. To również odpowiedź na pytanie, co to jest domena. Jak wybrać dobrą? Z
            jakich elementów się składa? Czy można ją kupić raz na zawsze oraz czy jej treść ma wpływ na widoczność w
            sieci? Na te i kilka innych pytań odpowiadamy poniżej. Zapraszamy do lektury!
          </strong>
        </p>
        <h2>Co to jest domena?</h2>
        <p>
          Z pewnością wiele razy słyszałeś/słyszałaś ten termin, ale czy wiesz, co oznacza domena? To unikalny adres
          internetowy, który pozwala na identyfikację strony WWW w sieci. Składa się z ciągu znaków, które są
          rozdzielone kropkami. Jej głównym zadaniem jest ułatwienie użytkownikom nawigacji po różnych witrynach i
          portalach, zastępując skomplikowane numery IP łatwiejszymi do zapamiętania nazwami. Nie możesz pomylić jej z
          adresem URL, ponieważ stanowi ona tylko jego część.
        </p>
        <p>
          Definicja domeny określa ten kluczowy element każdej strony internetowej (obok projektu i hostingu), za
          pierwszy punkt styku witryny z potencjalnym klientem.
        </p>
        <p>
          Warto wiedzieć, że ten element strony jest obsługiwany przez DNS (Domain Name System - System Nazw Domen),
          który odpowiada za tłumaczenie nazw domen na adresy IP, czyli numeryczne identyfikatory komputerów w sieci.
          Dzięki temu, użytkownicy mogą odwiedzać strony internetowe, wpisując przyjazne nazwy domen zamiast trudnych do
          zapamiętania adresów IP.
        </p>
        <h2>Z jakich elementów składa się unikalny adres strony WWW?</h2>
        <p>Chcesz poznać podstawowe elementy domeny, pod którymi kryje się strona internetowa? Oto one:</p>
        <ul>
          <li>
            <p>
              nazwa główna (
              <a href="https://panoramafirm.pl/" target="_blank" rel="noopener noreferrer">
                www.
                <strong>panoramafirm</strong>
                .pl
              </a>
              ),
            </p>
          </li>
          <li>
            <p>
              i rozszerzenia (
              <a href="https://panoramafirm.pl/" target="_blank" rel="noopener noreferrer">
                <strong>www.panoramafirm.pl</strong>
              </a>
              ).
            </p>
          </li>
        </ul>
        <p>
          Nazwa domeny to najczęściej nazwa portalu, firmy, produktu, zespołu itd. składająca się z małych liter, cyfr
          oraz znaków. Możesz też wybrać dowolne inne hasło, tak jak i rodzaj rozszerzenia – czyli TLD tzw. domena
          najwyższego poziomu (np. <strong>.pl, .com</strong>) lub domena drugiego poziomu (np.{" "}
          <strong>.com.pl, .net.pl</strong>).
        </p>
        <p>Rozpatrując adres WWW bardziej szczegółowo, można mówić również o takich składnikach jak:</p>
        <ul>
          <li>
            <p>
              protokół – czyli pierwszy człon adresu: <strong>http://</strong> lub <strong>https://</strong>,
            </p>
          </li>
          <li>
            <p>
              subdomeny – czyli słynne „WWW” (skrót: World Wilde Web), a także czasami spotykana nazwa dodawana przed
              domeną główną (np. www.<strong>subdomena</strong>.panoramafirm.pl).
            </p>
          </li>
        </ul>
        <h2>Jakie są rodzaje domen internetowych?</h2>
        <p>
          Wiesz już, co to domena i do czego jest. Pamiętaj, że można wyodrębnić kilka rodzajów, w zależności od ich
          zastosowania i poziomu hierarchii.
        </p>
        <h3>Domeny globalne gTLD</h3>
        <p>
          Zastanawiasz się, jak wybrać domenę? Możesz postawić na te najbardziej popularne, których końcówki to .com,
          .net czy .org. Mogą być rejestrowane przez użytkowników z całego świata. Znane są również jako generyczne
          nazwy domen.
        </p>
        <h3>Domeny narodowe/ domeny krajowe (ccTLD)</h3>
        <p>
          Określa się je jako domeny krajowe/narodowe, ponieważ są przypisane do poszczególnych krajów, np. .pl dla
          Polski, .de dla Niemiec czy .fr dla Francji. Zazwyczaj wykorzystują je strony internetowe związane z danym
          państwem. W bardziej szczegółowym podziale można by wyróżnić np. domeny makroregionów czy domeny regionalne.
        </p>
        <h3>Domeny funkcjonalne (sTLD)</h3>
        <p>
          Kolejna kategoria to unikalne adresy przeznaczone dla określonych grup użytkowników lub celów. Do takich
          przykładów możemy zaliczyć domeny z rozszerzeniem .edu dla instytucji edukacyjnych, .gov dla rządowych
          serwisów czy .mil dla witryn związanych z wojskiem. Ich zadaniem jest ułatwienie identyfikacji strony
          internetowej oraz jej celu.
        </p>
        <h3>Domena brandowa</h3>
        <p>
          Chcesz wybrać domenę, ale nie wiesz jaką? Możesz postawić na taką, które jest ściśle związana z nazwą Twojej
          firmy lub produktu. Jej głównym celem jest budowanie rozpoznawalności i wizerunku marki w Internecie. Domena
          brandowa powinna być łatwa do zapamiętania, unikalna i dobrze oddająca charakter działalności.
        </p>
        <h3>Domena parkowana</h3>
        <p>
          Co to domena parkowana? To taka, która została zarejestrowana, ale nie jest aktualnie wykorzystywana do
          prowadzenia aktywnej strony internetowej. Często wykorzystuje się je jako miejsce tymczasowe dla przyszłych
          projektów lub jako inwestycja, gdyż wartość takiego adresu może wzrosnąć z czasem.
        </p>
        <h2>Jak zamówić domenę i ile kosztuje rejestracja?</h2>
        <p>
          Zastanawiasz się, jak znaleźć wolną domenę i jak wykupić nazwę? Tak naprawdę nie da się jej kupić. Wszystkie
          adresy WWW są rejestrowane i służą danemu klientowi, dopóki opłaca on wykupiony abonament. Najmniejszy cykl
          wynajmu trwa zwykle 12 miesięcy.
        </p>
        <p>
          Jak wygląda więc cały proces? Wystarczy wykonać kilka kroków. Najpierw trzeba wybrać rejestratora domeny.
          Następnie musisz sprawdzić dostępność wybranej przez siebie nazwy i rozszerzenia, korzystając z wyszukiwarki
          na stronie dostawcy usługi. Jeśli są dostępne, to można przejść do rejestracji, wypełniając formularz z danymi
          osobowymi oraz wybierając okres, na jaki chce się zarejestrować domenę. Po dokonaniu płatności i otrzymaniu
          potwierdzenia, adres zostanie zarejestrowany i będzie gotowy do użycia.
        </p>
        <p>
          Teraz z pewnością interesuje Cię, ile kosztuje domena? Cena zależy przede wszystkim od dostawcy usług, okresu
          rejestracji oraz od samego adresu.
        </p>
        <p>
          Po wygaśnięciu abonamentu istnieje możliwość jego przedłużenia. Chcesz regularnie odnawiać domenę? Obecnie
          przedłużenie usługi w większości przypadków jest droższe niż pierwsza rejestracja. Istnieje również możliwość
          przeniesienia adresu, czyli zlecenia jego rejestracji innej firmie.
        </p>
        <p>
          Czy można określić żywotność domeny? W przypadku nieopłacenia adresu jest on jeszcze przez pewien czas
          (zazwyczaj ok. miesiąca) zarezerwowany dla poprzedniego wynajmującego. Jeśli nie dokona on wpłaty, adres
          ponownie staje się dostępny dla innych użytkowników.
        </p>
        <CallToAction
          to={"/"}
          text={"Wykonaj darmowy audyt strony i popraw jej widoczność w wyszukiwarce. Wykonaj teraz >>"}
        />
        <h2>Czym różni się dobra domena od hostingu i strony internetowej?</h2>
        <p>Wiesz już, jak zarejestrować domenę. Ale wróćmy jeszcze na chwilę do podstaw.</p>
        <p>
          Aby Twoja firma mogła wyświetlać się w sieci, potrzebne są 3 elementy: strona internetowa, omawiana tu domena
          i hosting. Jaka jest różnica?
        </p>
        <p>
          Przekazaliśmy Ci już sporo informacji o domenach, czyli adresach stron internetowych, które składają się z
          logicznie połączonych elementów wyświetlanych w przeglądarce. Strona WWW z kolei potrzebuje „miejsca
          przechowywania” w sieci, czyli non-stop działającego serwera, który umożliwi internautom korzystanie z danych
          zasobów przez całą dobę. Wynajem takiego serwera dla strony WWW nazywamy usługą hostingową.
        </p>
        <p>Ponieważ wiesz już, jak działa domena, to weź pod uwagę, że:</p>
        <ul>
          <li>
            <p>
              <strong>hosting</strong> to wynajęte miejsce na serwerze dla strony internetowej,
            </p>
          </li>
          <li>
            <p>
              <strong>strona internetowa</strong> to zbiór plików tworzący witrynę WWW.
            </p>
          </li>
        </ul>
        <h2>Jak wybrać dobrą nazwę i rozszerzenie? Wskazówki dotyczące rejestracji domeny</h2>
        <p>
          Wybór domeny jest równie trudny, jak wybór nazwy firmy, klubu czy zespołu muzycznego. Podczas wymyślania
          adresu dla strony internetowej, warto kierować się kilkoma podstawowymi zasadami. Otóż nazwa domeny nie
          powinna:
        </p>
        <ul>
          <li>
            <p>być długa i trudna do zapamiętania.</p>
          </li>
          <li>
            <p>zawierać trudnych ani zniesławiających wyrazów.</p>
          </li>
          <li>
            <p>mieć zbyt wielu znaków innych niż litery (cyfr, myślników).</p>
          </li>
        </ul>
        <p>
          Aby znaleźć dobrą domenę, musisz wybrać określenie kojarzące się z Twoją firmą lub działalnością. Pamiętaj
          również, że rozszerzenie powinno być powszechnienie znane (np. <strong>.pl, .com, .com.pl</strong>) lub
          związane z zawartością strony (np. kawiarnia<strong>.coffee</strong>, ksiegarnia<strong>.art</strong>).
        </p>
        <p>
          Zleć wykonanie <A to="https://audytseo.wenet.pl/" text={"audytu strony internetowej"} /> Popraw widoczność w
          wyszukiwarce!
        </p>
        <p>
          Ponadto wypada sprawdzić dostępność domeny, ponieważ trafny pomysł może okazać się już wykorzystany. Wówczas
          trzeba go zmienić i np. wybrać tą samą nazwę domeny, ale z innym rodzajem rozszerzenia lub sprawdzić, czy
          wybrany przez nas adres jest dostępny na rynku wtórnym.
        </p>
        <h2>Wybór domeny a SEO</h2>
        <p>
          Czy wykorzystanie domeny zawierającej słowa kluczowe ma wpływ na SEO? Od 2012 roku Google przestało
          faworyzować strony stosujące taką taktykę. Dlatego nienaturalne umieszczenie fraz w adresach internetowych
          raczej mija się z celem i nie wpływa pozytywnie na pozycjonowanie. Chyba, że istnieje możliwość rejestracji
          domeny związanej z konkretną branżą i miejscem (np. odpowiednia nazwa i rozszerzenie dla szewca działającego w
          Kielcach mogłyby wyglądać tak:{" "}
          <a
            href="http://audytseo.wenet.pl/baza-wiedzy/artykul/czym-jest-domena-internetowa-i-co-warto-o-niej-wiedziec/"
            target="_blank"
            rel="nofollow"
          >
            http://www.szewc-kielce.pl
          </a>
          ).
        </p>
        <p>
          Poza tym wyjątkiem znacznie lepiej postawić na oryginalną i łatwą do zapamiętania nazwę, która będzie
          przykuwać uwagę użytkowników, a jednocześnie informować o zawartości witryny.
        </p>
        <p>
          Obecnie wyszukiwarki uważają za bardziej wiarygodne i wartościowe te strony, które pojawiają się w wielu
          miejscach w sieci, dlatego chwytliwa, oryginalnie brzmiąca i odpowiednia nazwa domeny na pewno będzie
          skuteczniej wspierała SEO, stanowiąc niejako promocję samą w sobie.
        </p>
        <p>
          <br className="ProseMirror-trailingBreak" />
        </p>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "img/full/domena.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
  }
`

export default SeoArticle
